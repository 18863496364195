import {
  getFirestore,
  doc,
  collection,
  query,
  where,
  addDoc,
  getDoc,
  getDocs,
  updateDoc,
  setDoc,
  onSnapshot,
  deleteDoc,
} from "firebase/firestore";

let db = getFirestore();
let collectionName = "adminResearches";

class AdminResearch {
  constructor(
    id,
    session,
    forecastResearch,
    budgetResearch,
    resultResearch,
    stocksResearch
  ) {
    this.id = id;
    this.session = session;
    this.forecastResearch = forecastResearch;
    this.budgetResearch = budgetResearch;
    this.resultResearch = resultResearch;
    this.stocksResearch = stocksResearch;
  }

  static async initializeOne(sessionId) {
    let tmp_forecastResearch = {};
    let tmp_budgetResearch = {};
    let tmp_resultResearch = {};
    let tmp_stocksResearch = {};

    let tmp_research = new AdminResearch(
      null,
      sessionId,
      tmp_forecastResearch,
      tmp_budgetResearch,
      tmp_resultResearch,
      tmp_stocksResearch
    );
    return tmp_research;
  }

  static async getAll() {
    let collectionRef = collection(db, collectionName);
    let response = await getDocs(collectionRef);

    let adminResearches = [];

    response.forEach((doument) => {
      let data = doument.data();
      let tmp_research = new AdminResearch(
        doument.id,
        data.session,
        data.forecastResearch,
        data.budgetResearch,
        data.resultResearch,
        data.stocksResearch
      );
      adminResearches.push(tmp_research);
    });

    return adminResearches;
  }

  static async getById(id) {
    let documentRef = doc(db, collectionName, id);
    let response = await getDoc(documentRef);

    let data = response.data();
    let research = new AdminResearch(
      response.id,
      data.session,
      data.forecastResearch,
      data.budgetResearch,
      data.resultResearch,
      data.stocksResearch
    );

    return research;
  }

  static async getBySession(sessionId) {
    let collectionRef = collection(db, collectionName);
    let documentQuery = query(collectionRef, where("session", "==", sessionId));
    let response = await getDocs(documentQuery);

    let adminResearches = [];

    response.forEach((document) => {
      let data = document.data();
      let tmp_research = new AdminResearch(
        document.id,
        data.session,
        data.forecastResearch,
        data.budgetResearch,
        data.resultResearch,
        data.stocksResearch
      );
      adminResearches.push(tmp_research);
    });

    return adminResearches;
  }

  static listenAll(callback = null) {
    let collectionRef = collection(db, collectionName);
    let unsub = onSnapshot(collectionRef, (snapshot) => {
      let adminResearches = [];

      snapshot.forEach((document) => {
        let data = document.data();
        let tmp_research = new AdminResearch(
          document.id,
          data.session,
          data.forecastResearch,
          data.budgetResearch,
          data.resultResearch,
          data.stocksResearch
        );
        adminResearches.push(tmp_research);
      });

      if (callback != null) {
        callback(adminResearches);
      }
    });
    return unsub;
  }

  static listenById(id, callback = null) {
    let documentRef = doc(db, collectionName, id);
    let unsub = onSnapshot(documentRef, (snapshot) => {
      let data = snapshot.data();
      let research = new AdminResearch(
        snapshot.id,
        data.session,
        data.forecastResearch,
        data.budgetResearch,
        data.resultResearch,
        data.stocksResearch
      );

      if (callback != null) {
        callback(research);
      }
    });
    return unsub;
  }

  static async listenBySession(sessionId, callback = null) {
    let collectionRef = collection(db, collectionName);
    let documentsQuery = query(
      collectionRef,
      where("session", "==", sessionId)
    );

    // console.log("SESSION ID", sessionId);

    let unsub = onSnapshot(documentsQuery, (snapshot) => {
      let adminResearches = [];
      //   console.log("snapshot", snapshot);
      snapshot.forEach((document) => {
        let data = document.data();
        // console.log("DATA", data);
        let tmp_research = new AdminResearch(
          document.id,
          data.session,
          data.forecastResearch,
          data.budgetResearch,
          data.resultResearch,
          data.stocksResearch
        );
        // console.log("tmp_research", tmp_research);
        adminResearches.push(tmp_research);
      });

      if (callback != null) {
        callback(adminResearches);
      }
    });
    return unsub;
  }

  async save() {
    let collectionRef = collection(db, collectionName);
    if (this.id == null) {
      let response = await addDoc(collectionRef, {
        session: this.session,
        forecastResearch: this.forecastResearch,
        budgetResearch: this.budgetResearch,
        resultResearch: this.resultResearch,
        stocksResearch: this.stocksResearch,
      });
      this.id = response.id;
    } else {
      await updateDoc(doc(db, collectionName, this.id), {
        session: this.session,
        forecastResearch: this.forecastResearch,
        budgetResearch: this.budgetResearch,
        resultResearch: this.resultResearch,
        stocksResearch: this.stocksResearch,
      });
    }
    return this;
  }

  delete() {
    let documentRef = doc(db, collectionName, this.id);
    deleteDoc(documentRef);
  }
}

export default AdminResearch;
