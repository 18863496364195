<template>
  <div class="mt-5">
    <v-row>
      <v-col
        cols="12"
        class="d-flex flex-row align-center justify-center flex-wrap"
      >
        <v-btn
          class="ma-2"
          color="success"
          outlined
          @click="exportTable"
          v-if="researches"
        >
          <v-icon>mdi-file-excel-outline</v-icon>
          <span>Exporter cette étude</span>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-simple-table>
          <thead>
            <tr>
              <th class="by-1 br-1 bl-1 text-center" :rowspan="3">Univers</th>
              <th class="by-1 br-1 text-center" :rowspan="3">Équipe</th>
              <th class="by-1 br-1 bl-1 text-center" :rowspan="3">
                Capital social
              </th>

              <th
                class="by-1 br-1 bl-1 text-center"
                colspan="5"
                v-for="period in periods"
                v-text="period.name"
                v-if="!compactMode"
              ></th>
              <th class="by-1 br-1 bl-1 text-center" colspan="9">Total</th>
            </tr>
            <tr>
              <template v-for="period in periods" v-if="!compactMode">
                <th class="bb-1 br-1 bl-1 text-center" colspan="2">Resultat</th>
                <th class="bb-1 br-1 text-center" colspan="2">Rentabilité</th>
                <th class="bb-1 br-1 text-center" rowspan="2">Subvention</th>
              </template>

              <th class="bb-1 br-1 bl-1 text-center" colspan="4">Resultat</th>
              <th class="bb-1 br-1 text-center" colspan="4">Rentabilité</th>
              <th class="bb-1 br-1 text-center" rowspan="2">Subvention</th>
            </tr>
            <tr>
              <template v-for="period in periods" v-if="!compactMode">
                <th class="bb-1 br-1 bl-1 text-center">Avec subventions</th>
                <th class="bb-1 br-1 text-center">Classement</th>

                <th class="bb-1 br-1 text-center">Avec subventions</th>
                <th class="bb-1 br-1 text-center">Classement</th>
              </template>

              <th class="bb-1 br-1 bl-1 text-center">Avec subventions</th>
              <th class="bb-1 br-1 text-center">Classement (avec subv)</th>
              <th class="bb-1 br-1 text-center">Sans subventions</th>
              <th class="bb-1 br-1 text-center">Classement (Sans subv)</th>

              <th class="bb-1 br-1 text-center">Avec subventions</th>
              <th class="bb-1 br-1 text-center">Classement (avec subv)</th>
              <th class="bb-1 br-1 text-center">Sans subventions</th>
              <th class="bb-1 br-1 text-center">Classement (Sans subv)</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="universId in Object.keys(orderedTeams)">
              <tr>
                <th
                  :colspan="compactMode ? 12 : 12 + 5 * periods.length"
                  class="border"
                  style="height: 3px"
                ></th>
              </tr>
              <tr v-for="(team, teamIndex) in orderedTeams[universId]">
                <td
                  class="bb-1 br-1 bl-1 text-center"
                  :rowspan="orderedTeams[universId].length"
                  v-text="universeTable[universId].name"
                  v-if="teamIndex == 0"
                ></td>
                <td
                  class="bb-1 br-1 text-center"
                  v-text="team.name + '-' + team.publicName"
                ></td>
                <td
                  class="bb-1 br-1 bl-1 text-center"
                  v-text="
                    dataFormater.formatMoney(
                      researches.resultResearch[universId][team.id].total
                        .socialCapital
                    )
                  "
                ></td>

                <template v-for="period in periods" v-if="!compactMode">
                  <td
                    :class="
                      'bb-1 br-1 bl-1 text-center' +
                      (researches.resultResearch[universId][team.id].data[
                        period.id
                      ].rankByValue.byUnivers == 1
                        ? ' univers-max'
                        : '') +
                      (researches.resultResearch[universId][team.id].data[
                        period.id
                      ].rankByValue.byUnivers == orderedTeams[universId].length
                        ? ' univers-min'
                        : '') +
                      (researches.resultResearch[universId][team.id].data[
                        period.id
                      ].rankByValue.all == 1
                        ? ' global-max'
                        : '') +
                      (researches.resultResearch[universId][team.id].data[
                        period.id
                      ].rankByValue.all == orderedTeams[universId].length
                        ? ' global-min'
                        : '')
                    "
                    v-text="
                      dataFormater.formatMoney(
                        researches.resultResearch[universId][team.id].data[
                          period.id
                        ].value
                      )
                    "
                  ></td>
                  <td class="bb-1 br-1 text-center">
                    <span
                      v-text="
                        researches.resultResearch[universId][team.id].data[
                          period.id
                        ].rankByValue.byUnivers
                      "
                    ></span
                    ><sup>e</sup>
                  </td>
                  <td
                    :class="
                      'bb-1 br-1 text-center' +
                      (researches.resultResearch[universId][team.id].data[
                        period.id
                      ].rankByRentability.byUnivers == 1
                        ? ' univers-max'
                        : '') +
                      (researches.resultResearch[universId][team.id].data[
                        period.id
                      ].rankByRentability.byUnivers ==
                      orderedTeams[universId].length
                        ? ' univers-min'
                        : '') +
                      (researches.resultResearch[universId][team.id].data[
                        period.id
                      ].rankByRentability.all == 1
                        ? ' global-max'
                        : '') +
                      (researches.resultResearch[universId][team.id].data[
                        period.id
                      ].rankByRentability.all == orderedTeams[universId].length
                        ? ' global-min'
                        : '')
                    "
                    v-text="
                      dataFormater.formatPercentage(
                        researches.resultResearch[universId][team.id].data[
                          period.id
                        ].rentability
                      )
                    "
                  ></td>
                  <td class="bb-1 br-1 text-center">
                    <span
                      v-text="
                        researches.resultResearch[universId][team.id].data[
                          period.id
                        ].rankByRentability.byUnivers
                      "
                    ></span
                    ><sup>e</sup>
                  </td>
                  <td
                    class="bb-1 br-1 text-center"
                    v-text="
                      dataFormater.formatMoney(
                        researches.resultResearch[universId][team.id].data[
                          period.id
                        ].subventions
                      )
                    "
                  ></td>
                </template>

                <td
                  :class="
                    'bb-1 br-1 bl-1 text-center' +
                    (researches.resultResearch[universId][team.id].total
                      .withSubvention.rankByValue.byUnivers == 1
                      ? ' univers-max'
                      : '') +
                    (researches.resultResearch[universId][team.id].total
                      .withSubvention.rankByValue.byUnivers ==
                    orderedTeams[universId].length
                      ? ' univers-min'
                      : '') +
                    (researches.resultResearch[universId][team.id].total
                      .withSubvention.rankByValue.all == 1
                      ? ' global-max'
                      : '') +
                    (researches.resultResearch[universId][team.id].total
                      .withSubvention.rankByValue.all ==
                    orderedTeams[universId].length
                      ? ' global-min'
                      : '')
                  "
                  v-text="
                    dataFormater.formatMoney(
                      researches.resultResearch[universId][team.id].total
                        .withSubvention.value
                    )
                  "
                ></td>
                <td class="bb-1 br-1 text-center">
                  <span
                    v-text="
                      researches.resultResearch[universId][team.id].total
                        .withSubvention.rankByValue.byUnivers
                    "
                  ></span
                  ><sup>e</sup>
                </td>
                <td
                  :class="
                    'bb-1 br-1 text-center' +
                    (researches.resultResearch[universId][team.id].total
                      .withoutSubvention.rankByValue.byUnivers == 1
                      ? ' univers-max'
                      : '') +
                    (researches.resultResearch[universId][team.id].total
                      .withoutSubvention.rankByValue.byUnivers ==
                    orderedTeams[universId].length
                      ? ' univers-min'
                      : '') +
                    (researches.resultResearch[universId][team.id].total
                      .withoutSubvention.rankByValue.all == 1
                      ? ' global-max'
                      : '') +
                    (researches.resultResearch[universId][team.id].total
                      .withoutSubvention.rankByValue.all ==
                    orderedTeams[universId].length
                      ? ' global-min'
                      : '')
                  "
                  v-text="
                    dataFormater.formatMoney(
                      researches.resultResearch[universId][team.id].total
                        .withoutSubvention.value
                    )
                  "
                ></td>
                <td class="bb-1 br-1 text-center">
                  <span
                    v-text="
                      researches.resultResearch[universId][team.id].total
                        .withSubvention.rankByValue.byUnivers
                    "
                  ></span
                  ><sup>e</sup>
                </td>
                <td
                  :class="
                    'bb-1 br-1 text-center' +
                    (researches.resultResearch[universId][team.id].total
                      .withSubvention.rankByRentability.byUnivers == 1
                      ? ' univers-max'
                      : '') +
                    (researches.resultResearch[universId][team.id].total
                      .withSubvention.rankByRentability.byUnivers ==
                    orderedTeams[universId].length
                      ? ' univers-min'
                      : '') +
                    (researches.resultResearch[universId][team.id].total
                      .withSubvention.rankByRentability.all == 1
                      ? ' global-max'
                      : '') +
                    (researches.resultResearch[universId][team.id].total
                      .withSubvention.rankByRentability.all ==
                    orderedTeams[universId].length
                      ? ' global-min'
                      : '')
                  "
                  v-text="
                    dataFormater.formatPercentage(
                      researches.resultResearch[universId][team.id].total
                        .withSubvention.rentability
                    )
                  "
                ></td>
                <td class="bb-1 br-1 text-center">
                  <span
                    v-text="
                      researches.resultResearch[universId][team.id].total
                        .withSubvention.rankByRentability.byUnivers
                    "
                  ></span
                  ><sup>e</sup>
                </td>
                <td
                  :class="
                    'bb-1 br-1 text-center' +
                    (researches.resultResearch[universId][team.id].total
                      .withoutSubvention.rankByRentability.byUnivers == 1
                      ? ' univers-max'
                      : '') +
                    (researches.resultResearch[universId][team.id].total
                      .withoutSubvention.rankByRentability.byUnivers ==
                    orderedTeams[universId].length
                      ? ' univers-min'
                      : '') +
                    (researches.resultResearch[universId][team.id].total
                      .withoutSubvention.rankByRentability.all == 1
                      ? ' global-max'
                      : '') +
                    (researches.resultResearch[universId][team.id].total
                      .withoutSubvention.rankByRentability.all ==
                    orderedTeams[universId].length
                      ? ' global-min'
                      : '')
                  "
                  v-text="
                    dataFormater.formatPercentage(
                      researches.resultResearch[universId][team.id].total
                        .withoutSubvention.rentability
                    )
                  "
                ></td>
                <td class="bb-1 br-1 text-center">
                  <span
                    v-text="
                      researches.resultResearch[universId][team.id].total
                        .withSubvention.rankByRentability.byUnivers
                    "
                  ></span
                  ><sup>e</sup>
                </td>
                <td
                  :class="
                    'bb-1 br-1 text-center' +
                    (researches.resultResearch[universId][team.id].total
                      .rankBySubventions.byUnivers == 1
                      ? ' univers-max'
                      : '') +
                    (researches.resultResearch[universId][team.id].total
                      .rankBySubventions.byUnivers ==
                    orderedTeams[universId].length
                      ? ' univers-min'
                      : '') +
                    (researches.resultResearch[universId][team.id].total
                      .rankBySubventions.all == 1
                      ? ' global-max'
                      : '') +
                    (researches.resultResearch[universId][team.id].total
                      .rankBySubventions.all == orderedTeams[universId].length
                      ? ' global-min'
                      : '')
                  "
                  v-text="
                    dataFormater.formatMoney(
                      researches.resultResearch[universId][team.id].total
                        .subventions
                    )
                  "
                ></td>
              </tr>
            </template>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<style>
.univers-min {
  font-weight: bolder !important;
  color: var(--v-accent-base) !important;
}
.global-min {
  border: 3px solid var(--v-accent-base) !important;
}
.univers-max {
  font-weight: bolder !important;
  color: var(--v-primary-base) !important;
}
.global-max {
  border: 3px solid var(--v-primary-base) !important;
}
</style>

<script>
import dataFormater from "@/assets/functions/dataFormater.js";
import dataExtractor from "@/assets/functions/dataExtractor.js";

import Univers from "@/classes/Univers.js";
import Team from "@/classes/Team.js";
import Period from "@/classes/Period.js";

export default {
  name: "ResultResearch",
  props: ["session", "researches", "compactMode"],
  setup() {
    return {
      dataFormater,
      dataExtractor,
    };
  },
  data() {
    return {
      unsub: [],
      periods: [],
      teamTable: {},
      universeTable: {},
      orderedTeams: {},
    };
  },
  watch: {
    session: {
      async handler(val) {
        if (val) {
          this.loadData();
        }
      },
      immediate: true,
    },
    researches: {
      async handler(val) {
        if (val) {
          this.loadData();
        }
      },
      immediate: true,
    },
  },
  methods: {
    exportTable() {
      dataExtractor.exportTable(
        "adminResearchWraper",
        "resultResearch",
        "Etude des résultats"
      );
    },
    async loadData() {
      let listedPeriods = new Set();
      for (let universId in this.researches.budgetResearch) {
        for (let teamId in this.researches.budgetResearch[universId]) {
          for (let periodId in this.researches.budgetResearch[universId][teamId]
            .data) {
            listedPeriods.add(periodId);
          }
        }
      }

      this.unsub.push(
        Period.listenBySession(this.session.id, (periods) => {
          let tmpPeriods = [];
          for (let period of periods) {
            if (listedPeriods.has(period.id)) {
              tmpPeriods.push(period);
            }
          }

          tmpPeriods.sort((a, b) => a.end - b.end);
          this.periods = tmpPeriods;
        })
      );

      let tmp_orderedTeams = {};

      let universes = await Univers.getBySession(this.session.id);

      universes.sort((a, b) => a.name.localeCompare(b.name));

      for (let univers of universes) {
        this.universeTable[univers.id] = univers;

        let teams = await Team.getByUnivers(univers.id);
        for (let team of teams) {
          this.teamTable[team.id] = team;
        }
        tmp_orderedTeams[univers.id] = teams;
        tmp_orderedTeams[univers.id].sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      }
      // console.log("this.researches", this.researches);
      this.orderedTeams = tmp_orderedTeams;
    },
  },
};
</script>
