<template>
  <div class="mt-5">
    <v-row>
      <v-col
        cols="12"
        class="d-flex flex-row align-center justify-center flex-wrap"
      >
        <v-btn
          class="ma-2"
          color="success"
          outlined
          @click="exportTable"
          v-if="researches"
        >
          <v-icon>mdi-file-excel-outline</v-icon>
          <span>Exporter cette étude</span>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-simple-table>
          <thead>
            <tr>
              <th
                class="by-1 br-1 bl-1 text-center"
                :rowspan="compactMode ? 2 : 3"
              >
                Univers
              </th>
              <th class="by-1 br-1 text-center" :rowspan="compactMode ? 2 : 3">
                Équipe
              </th>

              <th
                class="by-1 br-1 bl-1 text-center"
                :colspan="compactMode ? 3 : 9"
                v-for="period in periods"
                v-text="period.name"
              ></th>
            </tr>
            <tr>
              <template v-for="period in periods">
                <th
                  class="bb-1 br-1 bl-1 text-center"
                  :colspan="compactMode ? 1 : 3"
                  v-text="
                    'Chiffre d\'affaires' + (compactMode ? ' (delta)' : '')
                  "
                ></th>
                <th
                  class="bb-1 br-1 text-center"
                  :colspan="compactMode ? 1 : 3"
                  v-text="'Résultat net' + (compactMode ? ' (delta)' : '')"
                ></th>
                <th
                  class="bb-1 br-1 text-center"
                  :colspan="compactMode ? 1 : 3"
                  v-text="'Tésorerie' + (compactMode ? ' (delta)' : '')"
                ></th>
              </template>
            </tr>
            <tr v-if="!compactMode">
              <template v-for="period in periods">
                <th class="bb-1 br-1 bl-1 text-center">Delta</th>
                <th class="bb-1 br-1 text-center">Prévu</th>
                <th class="bb-1 br-1 text-center">Réalisé</th>

                <th class="bb-1 br-1 text-center">Delta</th>
                <th class="bb-1 br-1 text-center">Prévu</th>
                <th class="bb-1 br-1 text-center">Réalisé</th>

                <th class="bb-1 br-1 text-center">Delta</th>
                <th class="bb-1 br-1 text-center">Prévu</th>
                <th class="bb-1 br-1 text-center">Réalisé</th>
              </template>
            </tr>
          </thead>
          <tbody>
            <template v-for="universId in Object.keys(orderedTeams)">
              <tr>
                <th
                  :colspan="
                    compactMode
                      ? 2 + 3 * periods.length
                      : 2 + 9 * periods.length
                  "
                  class="border"
                  style="height: 3px"
                ></th>
              </tr>
              <tr v-for="(team, teamIndex) in orderedTeams[universId]">
                <td
                  class="bb-1 br-1 bl-1 text-center"
                  :rowspan="orderedTeams[universId].length"
                  v-text="universeTable[universId].name"
                  v-if="teamIndex == 0"
                ></td>
                <td
                  class="bb-1 br-1 text-center"
                  v-text="team.name + '-' + team.publicName"
                ></td>
                <template v-for="period in periods">
                  <td
                    :class="
                      'bb-1 br-1 bl-1 text-center' +
                      (researches.forecastResearch[team.univers][team.id][
                        period.id
                      ].turnover.delta ==
                      universMin[team.univers][period.id].turnover
                        ? ' univers-max'
                        : '') +
                      (researches.forecastResearch[team.univers][team.id][
                        period.id
                      ].turnover.delta == globalMin[period.id].turnover
                        ? ' global-max'
                        : '')
                    "
                    v-text="
                      dataFormater.formatMoney(
                        researches.forecastResearch[team.univers][team.id][
                          period.id
                        ].turnover.delta
                      )
                    "
                  ></td>
                  <td
                    class="bb-1 br-1 text-center"
                    v-if="!compactMode"
                    v-text="
                      dataFormater.formatMoney(
                        researches.forecastResearch[team.univers][team.id][
                          period.id
                        ].turnover.forecast
                      )
                    "
                  ></td>
                  <td
                    class="bb-1 br-1 text-center"
                    v-if="!compactMode"
                    v-text="
                      dataFormater.formatMoney(
                        researches.forecastResearch[team.univers][team.id][
                          period.id
                        ].turnover.reality
                      )
                    "
                  ></td>

                  <td
                    :class="
                      'bb-1 br-1 text-center' +
                      (researches.forecastResearch[team.univers][team.id][
                        period.id
                      ].result.delta ==
                      universMin[team.univers][period.id].result
                        ? ' univers-max'
                        : '') +
                      (researches.forecastResearch[team.univers][team.id][
                        period.id
                      ].result.delta == globalMin[period.id].result
                        ? ' global-max'
                        : '')
                    "
                    v-text="
                      dataFormater.formatMoney(
                        researches.forecastResearch[team.univers][team.id][
                          period.id
                        ].result.delta
                      )
                    "
                  ></td>
                  <td
                    class="bb-1 br-1 text-center"
                    v-if="!compactMode"
                    v-text="
                      dataFormater.formatMoney(
                        researches.forecastResearch[team.univers][team.id][
                          period.id
                        ].result.forecast
                      )
                    "
                  ></td>
                  <td
                    class="bb-1 br-1 text-center"
                    v-if="!compactMode"
                    v-text="
                      dataFormater.formatMoney(
                        researches.forecastResearch[team.univers][team.id][
                          period.id
                        ].result.reality
                      )
                    "
                  ></td>

                  <td
                    :class="
                      'bb-1 br-1 text-center' +
                      (researches.forecastResearch[team.univers][team.id][
                        period.id
                      ].treasury.delta ==
                      universMin[team.univers][period.id].treasury
                        ? ' univers-max'
                        : '') +
                      (researches.forecastResearch[team.univers][team.id][
                        period.id
                      ].treasury.delta == globalMin[period.id].treasury
                        ? ' global-max'
                        : '')
                    "
                    v-text="
                      dataFormater.formatMoney(
                        researches.forecastResearch[team.univers][team.id][
                          period.id
                        ].treasury.delta
                      )
                    "
                  ></td>
                  <td
                    class="bb-1 br-1 text-center"
                    v-if="!compactMode"
                    v-text="
                      dataFormater.formatMoney(
                        researches.forecastResearch[team.univers][team.id][
                          period.id
                        ].result.forecast
                      )
                    "
                  ></td>
                  <td
                    class="bb-1 br-1 text-center"
                    v-if="!compactMode"
                    v-text="
                      dataFormater.formatMoney(
                        researches.forecastResearch[team.univers][team.id][
                          period.id
                        ].result.reality
                      )
                    "
                  ></td>
                </template>
              </tr>
            </template>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
.univers-max {
  font-weight: bolder !important;
  color: var(--v-primary-base) !important;
}
.global-max {
  border: 3px solid var(--v-primary-base) !important;
}
</style>

<script>
import dataFormater from "@/assets/functions/dataFormater.js";
import dataExtractor from "@/assets/functions/dataExtractor.js";

import Univers from "@/classes/Univers.js";
import Team from "@/classes/Team.js";
import Period from "@/classes/Period.js";

export default {
  name: "ForecastResearch",
  props: ["session", "researches", "compactMode"],
  setup() {
    return {
      dataFormater,
      dataExtractor,
    };
  },
  data() {
    return {
      unsub: [],
      periods: [],
      teamTable: {},
      universeTable: {},
      orderedTeams: {},
    };
  },
  watch: {
    session: {
      async handler(val) {
        if (val) {
          this.loadData();
        }
      },
      immediate: true,
    },
    researches: {
      async handler(val) {
        if (val) {
          this.loadData();
        }
      },
      immediate: true,
    },
  },
  computed: {
    universMin() {
      let universMin = {};
      for (let universId in this.researches.forecastResearch) {
        if (universMin[universId] == undefined) {
          universMin[universId] = {};
        }
        for (let teamId in this.researches.forecastResearch[universId]) {
          for (let periodId in this.researches.forecastResearch[universId][
            teamId
          ]) {
            if (universMin[universId][periodId] == undefined) {
              universMin[universId][periodId] = {
                turnover: Infinity,
                result: Infinity,
                treasury: Infinity,
              };
            }
            if (
              this.researches.forecastResearch[universId][teamId][periodId]
                .turnover.delta < universMin[universId][periodId].turnover
            ) {
              universMin[universId][periodId].turnover =
                this.researches.forecastResearch[universId][teamId][
                  periodId
                ].turnover.delta;
            }
            if (
              this.researches.forecastResearch[universId][teamId][periodId]
                .result.delta < universMin[universId][periodId].result
            ) {
              universMin[universId][periodId].result =
                this.researches.forecastResearch[universId][teamId][
                  periodId
                ].result.delta;
            }
            if (
              this.researches.forecastResearch[universId][teamId][periodId]
                .treasury.delta < universMin[universId][periodId].treasury
            ) {
              universMin[universId][periodId].treasury =
                this.researches.forecastResearch[universId][teamId][
                  periodId
                ].treasury.delta;
            }
          }
        }
      }
      return universMin;
    },
    globalMin() {
      let globalMin = {};
      for (let universId in this.universMin) {
        for (let periodId in this.universMin[universId]) {
          if (globalMin[periodId] == undefined) {
            globalMin[periodId] = {
              turnover: Infinity,
              result: Infinity,
              treasury: Infinity,
            };
          }
          if (
            this.universMin[universId][periodId].turnover <
            globalMin[periodId].turnover
          ) {
            globalMin[periodId].turnover =
              this.universMin[universId][periodId].turnover;
          }
          if (
            this.universMin[universId][periodId].result <
            globalMin[periodId].result
          ) {
            globalMin[periodId].result =
              this.universMin[universId][periodId].result;
          }
          if (
            this.universMin[universId][periodId].treasury <
            globalMin[periodId].treasury
          ) {
            globalMin[periodId].treasury =
              this.universMin[universId][periodId].treasury;
          }
        }
      }
      return globalMin;
    },
  },
  methods: {
    exportTable() {
      dataExtractor.exportTable(
        "adminResearchWraper",
        "forecastResearch",
        "Etude de prévisions"
      );
    },
    async loadData() {
      let listedPeriods = new Set();
      for (let universId in this.researches.forecastResearch) {
        for (let teamId in this.researches.forecastResearch[universId]) {
          for (let periodId in this.researches.forecastResearch[universId][
            teamId
          ]) {
            listedPeriods.add(periodId);
          }
        }
      }

      this.unsub.push(
        Period.listenBySession(this.session.id, (periods) => {
          let tmpPeriods = [];
          for (let period of periods) {
            if (listedPeriods.has(period.id)) {
              tmpPeriods.push(period);
            }
          }

          tmpPeriods.sort((a, b) => a.end - b.end);
          this.periods = tmpPeriods;
        })
      );

      let tmp_orderedTeams = {};
      let universes = await Univers.getBySession(this.session.id);
      universes.sort((a, b) => a.name.localeCompare(b.name));

      for (let univers of universes) {
        this.universeTable[univers.id] = univers;

        let teams = await Team.getByUnivers(univers.id);
        for (let team of teams) {
          this.teamTable[team.id] = team;
        }
        tmp_orderedTeams[univers.id] = teams;
        tmp_orderedTeams[univers.id].sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      }
      this.orderedTeams = tmp_orderedTeams;
    },
  },
};
</script>
