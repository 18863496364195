var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-5"},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row align-center justify-center flex-wrap",attrs:{"cols":"12"}},[(_vm.researches)?_c('v-btn',{staticClass:"ma-2",attrs:{"color":"success","outlined":""},on:{"click":_vm.exportTable}},[_c('v-icon',[_vm._v("mdi-file-excel-outline")]),_c('span',[_vm._v("Exporter cette étude")])],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',{staticClass:"by-1 br-1 bl-1 text-center",attrs:{"rowspan":_vm.compactMode ? 2 : 3}},[_vm._v(" Univers ")]),_c('th',{staticClass:"by-1 br-1 text-center",attrs:{"rowspan":_vm.compactMode ? 2 : 3}},[_vm._v(" Équipe ")]),_vm._l((_vm.periods),function(period){return _c('th',{staticClass:"by-1 br-1 bl-1 text-center",attrs:{"colspan":_vm.compactMode ? 3 : 9},domProps:{"textContent":_vm._s(period.name)}})})],2),_c('tr',[_vm._l((_vm.periods),function(period){return [_c('th',{staticClass:"bb-1 br-1 bl-1 text-center",attrs:{"colspan":_vm.compactMode ? 1 : 3},domProps:{"textContent":_vm._s(
                  'Chiffre d\'affaires' + (_vm.compactMode ? ' (delta)' : '')
                )}}),_c('th',{staticClass:"bb-1 br-1 text-center",attrs:{"colspan":_vm.compactMode ? 1 : 3},domProps:{"textContent":_vm._s('Résultat net' + (_vm.compactMode ? ' (delta)' : ''))}}),_c('th',{staticClass:"bb-1 br-1 text-center",attrs:{"colspan":_vm.compactMode ? 1 : 3},domProps:{"textContent":_vm._s('Tésorerie' + (_vm.compactMode ? ' (delta)' : ''))}})]})],2),(!_vm.compactMode)?_c('tr',[_vm._l((_vm.periods),function(period){return [_c('th',{staticClass:"bb-1 br-1 bl-1 text-center"},[_vm._v("Delta")]),_c('th',{staticClass:"bb-1 br-1 text-center"},[_vm._v("Prévu")]),_c('th',{staticClass:"bb-1 br-1 text-center"},[_vm._v("Réalisé")]),_c('th',{staticClass:"bb-1 br-1 text-center"},[_vm._v("Delta")]),_c('th',{staticClass:"bb-1 br-1 text-center"},[_vm._v("Prévu")]),_c('th',{staticClass:"bb-1 br-1 text-center"},[_vm._v("Réalisé")]),_c('th',{staticClass:"bb-1 br-1 text-center"},[_vm._v("Delta")]),_c('th',{staticClass:"bb-1 br-1 text-center"},[_vm._v("Prévu")]),_c('th',{staticClass:"bb-1 br-1 text-center"},[_vm._v("Réalisé")])]})],2):_vm._e()]),_c('tbody',[_vm._l((Object.keys(_vm.orderedTeams)),function(universId){return [_c('tr',[_c('th',{staticClass:"border",staticStyle:{"height":"3px"},attrs:{"colspan":_vm.compactMode
                    ? 2 + 3 * _vm.periods.length
                    : 2 + 9 * _vm.periods.length}})]),_vm._l((_vm.orderedTeams[universId]),function(team,teamIndex){return _c('tr',[(teamIndex == 0)?_c('td',{staticClass:"bb-1 br-1 bl-1 text-center",attrs:{"rowspan":_vm.orderedTeams[universId].length},domProps:{"textContent":_vm._s(_vm.universeTable[universId].name)}}):_vm._e(),_c('td',{staticClass:"bb-1 br-1 text-center",domProps:{"textContent":_vm._s(team.name + '-' + team.publicName)}}),_vm._l((_vm.periods),function(period){return [_c('td',{class:'bb-1 br-1 bl-1 text-center' +
                    (_vm.researches.forecastResearch[team.univers][team.id][
                      period.id
                    ].turnover.delta ==
                    _vm.universMin[team.univers][period.id].turnover
                      ? ' univers-max'
                      : '') +
                    (_vm.researches.forecastResearch[team.univers][team.id][
                      period.id
                    ].turnover.delta == _vm.globalMin[period.id].turnover
                      ? ' global-max'
                      : ''),domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      _vm.researches.forecastResearch[team.univers][team.id][
                        period.id
                      ].turnover.delta
                    )
                  )}}),(!_vm.compactMode)?_c('td',{staticClass:"bb-1 br-1 text-center",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      _vm.researches.forecastResearch[team.univers][team.id][
                        period.id
                      ].turnover.forecast
                    )
                  )}}):_vm._e(),(!_vm.compactMode)?_c('td',{staticClass:"bb-1 br-1 text-center",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      _vm.researches.forecastResearch[team.univers][team.id][
                        period.id
                      ].turnover.reality
                    )
                  )}}):_vm._e(),_c('td',{class:'bb-1 br-1 text-center' +
                    (_vm.researches.forecastResearch[team.univers][team.id][
                      period.id
                    ].result.delta ==
                    _vm.universMin[team.univers][period.id].result
                      ? ' univers-max'
                      : '') +
                    (_vm.researches.forecastResearch[team.univers][team.id][
                      period.id
                    ].result.delta == _vm.globalMin[period.id].result
                      ? ' global-max'
                      : ''),domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      _vm.researches.forecastResearch[team.univers][team.id][
                        period.id
                      ].result.delta
                    )
                  )}}),(!_vm.compactMode)?_c('td',{staticClass:"bb-1 br-1 text-center",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      _vm.researches.forecastResearch[team.univers][team.id][
                        period.id
                      ].result.forecast
                    )
                  )}}):_vm._e(),(!_vm.compactMode)?_c('td',{staticClass:"bb-1 br-1 text-center",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      _vm.researches.forecastResearch[team.univers][team.id][
                        period.id
                      ].result.reality
                    )
                  )}}):_vm._e(),_c('td',{class:'bb-1 br-1 text-center' +
                    (_vm.researches.forecastResearch[team.univers][team.id][
                      period.id
                    ].treasury.delta ==
                    _vm.universMin[team.univers][period.id].treasury
                      ? ' univers-max'
                      : '') +
                    (_vm.researches.forecastResearch[team.univers][team.id][
                      period.id
                    ].treasury.delta == _vm.globalMin[period.id].treasury
                      ? ' global-max'
                      : ''),domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      _vm.researches.forecastResearch[team.univers][team.id][
                        period.id
                      ].treasury.delta
                    )
                  )}}),(!_vm.compactMode)?_c('td',{staticClass:"bb-1 br-1 text-center",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      _vm.researches.forecastResearch[team.univers][team.id][
                        period.id
                      ].result.forecast
                    )
                  )}}):_vm._e(),(!_vm.compactMode)?_c('td',{staticClass:"bb-1 br-1 text-center",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      _vm.researches.forecastResearch[team.univers][team.id][
                        period.id
                      ].result.reality
                    )
                  )}}):_vm._e()]})],2)})]})],2)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }