<template>
  <div class="mt-5">
    <v-row>
      <v-col
        cols="12"
        class="d-flex flex-row align-center justify-center flex-wrap"
      >
        <v-btn
          class="ma-2"
          color="success"
          outlined
          @click="exportTable"
          v-if="researches"
        >
          <v-icon>mdi-file-excel-outline</v-icon>
          <span>Exporter cette étude</span>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-simple-table>
          <thead>
            <tr>
              <th class="by-1 br-1 bl-1 text-center" :rowspan="2">Univers</th>
              <th class="by-1 br-1 text-center" :rowspan="2">Équipe</th>

              <th
                class="by-1 br-1 bl-1 text-center"
                colspan="4"
                v-for="period in periods"
                v-text="period.name"
                v-if="!compactMode"
              ></th>
              <th class="by-1 br-1 bl-1 text-center" colspan="4">Total</th>
            </tr>
            <tr>
              <template v-for="period in periods" v-if="!compactMode">
                <th class="bb-1 br-1 bl-1 text-center">Valeur du stock</th>
                <th class="bb-1 br-1 text-center">Variation du stock</th>
                <th class="bb-1 br-1 text-center">Ventes manquées</th>
                <th class="bb-1 br-1 text-center">part du CA manquées</th>
              </template>

              <th class="bb-1 br-1 bl-1 text-center">
                Valeur moyenne du stock
              </th>
              <th class="bb-1 br-1 text-center">Variation moyenne du stock</th>
              <th class="bb-1 br-1 text-center">Moyenne de ventes manquées</th>
              <th class="bb-1 br-1 text-center">part moyenne du CA manquées</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="universId in Object.keys(orderedTeams)">
              <tr>
                <th
                  :colspan="compactMode ? 12 : 12 + 5 * periods.length"
                  class="border"
                  style="height: 3px"
                ></th>
              </tr>
              <tr v-for="(team, teamIndex) in orderedTeams[universId]">
                <td
                  class="bb-1 br-1 bl-1 text-center"
                  :rowspan="orderedTeams[universId].length"
                  v-text="universeTable[universId].name"
                  v-if="teamIndex == 0"
                ></td>
                <td
                  class="bb-1 br-1 text-center"
                  v-text="team.name + '-' + team.publicName"
                ></td>

                <template v-for="period in periods" v-if="!compactMode">
                  <td
                    class="bb-1 br-1 bl-1 text-center"
                    v-text="
                      dataFormater.formatMoney(
                        researches.stocksResearch.data[universId][team.id].data[
                          period.id
                        ].value
                      )
                    "
                  ></td>
                  <td
                    class="bb-1 br-1 text-center"
                    v-text="
                      dataFormater.formatMoney(
                        researches.stocksResearch.data[universId][team.id].data[
                          period.id
                        ].variation
                      )
                    "
                  ></td>
                  <td
                    class="bb-1 br-1 text-center"
                    v-text="
                      dataFormater.formatMoney(
                        researches.stocksResearch.data[universId][team.id].data[
                          period.id
                        ].salesLossValue
                      )
                    "
                  ></td>
                  <td
                    class="bb-1 br-1 text-center"
                    v-text="
                      dataFormater.formatPercentage(
                        researches.stocksResearch.data[universId][team.id].data[
                          period.id
                        ].salesLossRate * 100
                      )
                    "
                  ></td>
                </template>

                <td
                  :class="
                    'bb-1 br-1 bl-1 text-center' +
                    (researches.stocksResearch.max.total.value ==
                    researches.stocksResearch.data[universId][team.id].total
                      .meanValue
                      ? ' global-min'
                      : '') +
                    (researches.stocksResearch.max.byUnivers[universId].value ==
                    researches.stocksResearch.data[universId][team.id].total
                      .meanValue
                      ? ' univers-min'
                      : '')
                  "
                  v-text="
                    dataFormater.formatMoney(
                      researches.stocksResearch.data[universId][team.id].total
                        .meanValue
                    )
                  "
                ></td>
                <td
                  :class="
                    'bb-1 br-1 text-center' +
                    (researches.stocksResearch.max.total.variation ==
                    researches.stocksResearch.data[universId][team.id].total
                      .meanVariation
                      ? ' global-min'
                      : '') +
                    (researches.stocksResearch.max.byUnivers[universId]
                      .variation ==
                    researches.stocksResearch.data[universId][team.id].total
                      .meanVariation
                      ? ' univers-min'
                      : '')
                  "
                  v-text="
                    dataFormater.formatMoney(
                      researches.stocksResearch.data[universId][team.id].total
                        .meanVariation
                    )
                  "
                ></td>
                <td
                  :class="
                    'bb-1 br-1 text-center' +
                    (researches.stocksResearch.max.total.salesLossValue ==
                    researches.stocksResearch.data[universId][team.id].total
                      .salesLossMeanValue
                      ? ' global-min'
                      : '') +
                    (researches.stocksResearch.max.byUnivers[universId]
                      .salesLossValue ==
                    researches.stocksResearch.data[universId][team.id].total
                      .salesLossMeanValue
                      ? ' univers-min'
                      : '')
                  "
                  v-text="
                    dataFormater.formatMoney(
                      researches.stocksResearch.data[universId][team.id].total
                        .salesLossMeanValue
                    )
                  "
                ></td>
                <td
                  :class="
                    'bb-1 br-1 text-center' +
                    (researches.stocksResearch.max.total.salesLossRate ==
                    researches.stocksResearch.data[universId][team.id].total
                      .salesLossMeanRate
                      ? ' global-min'
                      : '') +
                    (researches.stocksResearch.max.byUnivers[universId]
                      .salesLossRate ==
                    researches.stocksResearch.data[universId][team.id].total
                      .salesLossMeanRate
                      ? ' univers-min'
                      : '')
                  "
                  v-text="
                    dataFormater.formatPercentage(
                      researches.stocksResearch.data[universId][team.id].total
                        .salesLossMeanRate * 100
                    )
                  "
                ></td>
              </tr>
            </template>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<style>
.univers-min {
  font-weight: bolder !important;
  color: var(--v-accent-base) !important;
}
.global-min {
  border: 3px solid var(--v-accent-base) !important;
}
</style>

<script>
import dataFormater from "@/assets/functions/dataFormater.js";
import dataExtractor from "@/assets/functions/dataExtractor.js";

import Univers from "@/classes/Univers.js";
import Team from "@/classes/Team.js";
import Period from "@/classes/Period.js";

export default {
  name: "StockResearch",
  props: ["session", "researches", "compactMode"],
  setup() {
    return {
      dataFormater,
      dataExtractor,
    };
  },
  data() {
    return {
      unsub: [],
      periods: [],
      teamTable: {},
      universeTable: {},
      orderedTeams: {},
    };
  },
  watch: {
    session: {
      async handler(val) {
        if (val) {
          this.loadData();
        }
      },
      immediate: true,
    },
    researches: {
      async handler(val) {
        if (val) {
          this.loadData();
        }
      },
      immediate: true,
    },
  },
  methods: {
    exportTable() {
      dataExtractor.exportTable(
        "adminResearchWraper",
        "stockResearch",
        "Etude des stocks"
      );
    },
    async loadData() {
      let listedPeriods = new Set();
      for (let universId in this.researches.budgetResearch) {
        for (let teamId in this.researches.budgetResearch[universId]) {
          for (let periodId in this.researches.budgetResearch[universId][teamId]
            .data) {
            listedPeriods.add(periodId);
          }
        }
      }

      this.unsub.push(
        Period.listenBySession(this.session.id, (periods) => {
          let tmpPeriods = [];
          for (let period of periods) {
            if (listedPeriods.has(period.id)) {
              tmpPeriods.push(period);
            }
          }

          tmpPeriods.sort((a, b) => a.end - b.end);
          this.periods = tmpPeriods;
        })
      );

      let tmp_orderedTeams = {};
      let universes = await Univers.getBySession(this.session.id);
      universes.sort((a, b) => a.name.localeCompare(b.name));

      for (let univers of universes) {
        this.universeTable[univers.id] = univers;

        let teams = await Team.getByUnivers(univers.id);
        for (let team of teams) {
          this.teamTable[team.id] = team;
        }
        tmp_orderedTeams[univers.id] = teams;
        tmp_orderedTeams[univers.id].sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      }
      this.orderedTeams = tmp_orderedTeams;
    },
  },
};
</script>
