<template>
  <span v-if="profile.role != 'User'">
    <v-card>
      <v-card-title primary-title class="justify-center">
        <h1 class="text-center mb-3">Etudes admin</h1>
      </v-card-title>
      <v-card-text>
        <v-row class="d-flex flex-row align-center justify-center">
          <v-col cols="12" md="4">
            <v-select
              :items="sessions"
              item-text="name"
              return-object
              label="Sélectionnez une session"
              v-model="selectedSession"
              @change="OnSessionSelected"
              hide-details=""
            ></v-select>
          </v-col>
        </v-row>
        <v-row
          class="mb-5 d-flex flex-row align-center justify-center"
          v-if="selectedSession && currentResearches"
        >
          <div>
            <v-switch
              color="accent"
              v-model="compactMode"
              label="Affichage en mode compact"
            ></v-switch>
          </div>
        </v-row>
        <span v-if="selectedSession && currentResearches">
          <v-tabs v-model="currentTab" color="primary" centered>
            <v-tab>
              <span>Etudes des prévisions</span>
            </v-tab>
            <v-tab>
              <span>Etudes des budgets</span>
            </v-tab>
            <v-tab>
              <span>Etudes des résultats</span>
            </v-tab>
            <v-tab>
              <span>Etudes des stocks</span>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="currentTab" id="adminResearchWraper">
            <v-tab-item>
              <span id="forecastResearch">
                <ForecastResearch
                  :session="selectedSession"
                  :researches="currentResearches"
                  :compactMode="compactMode"
                />
              </span>
            </v-tab-item>
            <v-tab-item>
              <span id="budgetResearch">
                <BudgetResearch
                  :session="selectedSession"
                  :researches="currentResearches"
                  :compactMode="compactMode"
                />
              </span>
            </v-tab-item>
            <v-tab-item>
              <span id="resultResearch">
                <ResultResearch
                  :session="selectedSession"
                  :researches="currentResearches"
                  :compactMode="compactMode"
                />
              </span>
            </v-tab-item>
            <v-tab-item>
              <span id="stockResearch">
                <StockResearch
                  :session="selectedSession"
                  :researches="currentResearches"
                  :compactMode="compactMode"
                />
              </span>
            </v-tab-item>
          </v-tabs-items>
        </span>
      </v-card-text>
    </v-card>
  </span>
</template>

<script>
import Session from "@/classes/Session";
import Univers from "@/classes/Univers";
import Period from "@/classes/Period";
import Team from "@/classes/Team";
import AdminResearch from "@/classes/AdminResearch";

import dataFormater from "@/assets/functions/dataFormater.js";

import Swal from "sweetalert2/dist/sweetalert2.js";

import ForecastResearch from "@/components/adminResearches/ForecastResearch.vue";
import BudgetResearch from "@/components/adminResearches/BudgetResearch.vue";
import ResultResearch from "@/components/adminResearches/ResultResearch.vue";
import StockResearch from "@/components/adminResearches/StockResearch.vue";

export default {
  name: "Researches",
  props: ["user", "profile", "userSession"],
  components: {
    ForecastResearch,
    BudgetResearch,
    ResultResearch,
    StockResearch,
  },
  setup() {
    return {
      dataFormater,
    };
  },
  data() {
    return {
      unsub: [],

      sessions: [],
      teams: [],
      periods: [],

      selectedSession: null,
      compactMode: true,

      currentTab: null,

      currentResearches: null,
    };
  },
  async created() {
    this.unsub.push(
      Session.listenOnlySession((sessions) => {
        sessions.sort((a, b) => b.date - a.date);
        this.sessions = sessions;
      })
    );
  },
  methods: {
    async OnSessionSelected() {
      this.currentResearches = null;
      await this.loadResearches();
    },
    async loadResearches() {
      this.unsub.push(
        AdminResearch.listenBySession(
          this.selectedSession.id,
          (adminResearches) => {
            this.currentResearches = adminResearches[0];
            // console.log("this.selectedSession.id", this.selectedSession.id);
            // console.log("this.currentResearches", adminResearches);
          }
        )
      );
    },
  },
  destroyed() {
    this.unsub.forEach((unsub) => {
      if (typeof unsub == "function") {
        unsub();
      }
    });
  },
};
</script>
