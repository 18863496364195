<template>
  <div class="mt-5">
    <v-row>
      <v-col
        cols="12"
        class="d-flex flex-row align-center justify-center flex-wrap"
      >
        <v-btn
          class="ma-2"
          color="success"
          outlined
          @click="exportTable"
          v-if="researches"
        >
          <v-icon>mdi-file-excel-outline</v-icon>
          <span>Exporter cette étude</span>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-simple-table>
          <thead>
            <tr>
              <th class="by-1 br-1 bl-1 text-center" :rowspan="2">Univers</th>
              <th class="by-1 br-1 text-center" :rowspan="2">Équipe</th>

              <th
                class="by-1 br-1 bl-1 text-center"
                colspan="5"
                v-for="period in periods"
                v-text="period.name"
                v-if="!compactMode"
              ></th>
              <th class="by-1 br-1 bl-1 text-center" colspan="5">Total</th>
            </tr>
            <tr>
              <template v-for="period in periods" v-if="!compactMode">
                <th class="bb-1 br-1 bl-1 text-center">
                  Budget RSE et Qualité
                </th>
                <th class="bb-1 br-1 text-center">Budget R&D</th>
                <th class="bb-1 br-1 text-center">Budget Communication</th>
                <th class="bb-1 br-1 text-center">Budget Marketing</th>
                <th class="bb-1 br-1 text-center">Total</th>
              </template>

              <th class="bb-1 br-1 bl-1 text-center">Budget RSE et Qualité</th>
              <th class="bb-1 br-1 text-center">Budget R&D</th>
              <th class="bb-1 br-1 text-center">Budget Communication</th>
              <th class="bb-1 br-1 text-center">Budget Marketing</th>
              <th class="bb-1 br-1 text-center">Total</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="universId in Object.keys(orderedTeams)">
              <tr>
                <th
                  :colspan="compactMode ? 7 : 7 + 5 * periods.length"
                  class="border"
                  style="height: 3px"
                ></th>
              </tr>
              <tr v-for="(team, teamIndex) in orderedTeams[universId]">
                <td
                  class="bb-1 br-1 bl-1 text-center"
                  :rowspan="orderedTeams[universId].length"
                  v-text="universeTable[universId].name"
                  v-if="teamIndex == 0"
                ></td>
                <td
                  class="bb-1 br-1 text-center"
                  v-text="team.name + '-' + team.publicName"
                ></td>

                <template v-for="period in periods" v-if="!compactMode">
                  <td
                    class="bb-1 br-1 bl-1 text-center"
                    v-text="
                      dataFormater.formatMoney(
                        researches.budgetResearch[team.univers][team.id].data[
                          period.id
                        ].quality
                      )
                    "
                  ></td>
                  <td
                    class="bb-1 br-1 text-center"
                    v-text="
                      dataFormater.formatMoney(
                        researches.budgetResearch[team.univers][team.id].data[
                          period.id
                        ].rnd
                      )
                    "
                  ></td>
                  <td
                    class="bb-1 br-1 text-center"
                    v-text="
                      dataFormater.formatMoney(
                        researches.budgetResearch[team.univers][team.id].data[
                          period.id
                        ].communication
                      )
                    "
                  ></td>
                  <td
                    class="bb-1 br-1 text-center"
                    v-text="
                      dataFormater.formatMoney(
                        researches.budgetResearch[team.univers][team.id].data[
                          period.id
                        ].marketing
                      )
                    "
                  ></td>
                  <td
                    class="bb-1 br-1 text-center"
                    v-text="
                      dataFormater.formatMoney(
                        researches.budgetResearch[team.univers][team.id].data[
                          period.id
                        ].all
                      )
                    "
                  ></td>
                </template>

                <td
                  :class="
                    'bb-1 br-1 bl-1 text-center' +
                    (researches.budgetResearch[team.univers][team.id].total
                      .quality == universMin[team.univers].quality
                      ? ' univers-min'
                      : '') +
                    (researches.budgetResearch[team.univers][team.id].total
                      .quality == universMax[team.univers].quality
                      ? ' univers-max'
                      : '') +
                    (researches.budgetResearch[team.univers][team.id].total
                      .quality == globalMin.quality
                      ? ' global-min'
                      : '') +
                    (researches.budgetResearch[team.univers][team.id].total
                      .quality == globalMax.quality
                      ? ' global-max'
                      : '')
                  "
                  v-text="
                    dataFormater.formatMoney(
                      researches.budgetResearch[team.univers][team.id].total
                        .quality
                    )
                  "
                ></td>
                <td
                  :class="
                    'bb-1 br-1 text-center' +
                    (researches.budgetResearch[team.univers][team.id].total
                      .rnd == universMin[team.univers].rnd
                      ? ' univers-min'
                      : '') +
                    (researches.budgetResearch[team.univers][team.id].total
                      .rnd == universMax[team.univers].rnd
                      ? ' univers-max'
                      : '') +
                    (researches.budgetResearch[team.univers][team.id].total
                      .rnd == globalMin.rnd
                      ? ' global-min'
                      : '') +
                    (researches.budgetResearch[team.univers][team.id].total
                      .rnd == globalMax.rnd
                      ? ' global-max'
                      : '')
                  "
                  v-text="
                    dataFormater.formatMoney(
                      researches.budgetResearch[team.univers][team.id].total.rnd
                    )
                  "
                ></td>
                <td
                  :class="
                    'bb-1 br-1 text-center' +
                    (researches.budgetResearch[team.univers][team.id].total
                      .communication == universMin[team.univers].communication
                      ? ' univers-min'
                      : '') +
                    (researches.budgetResearch[team.univers][team.id].total
                      .communication == universMax[team.univers].communication
                      ? ' univers-max'
                      : '') +
                    (researches.budgetResearch[team.univers][team.id].total
                      .communication == globalMin.communication
                      ? ' global-min'
                      : '') +
                    (researches.budgetResearch[team.univers][team.id].total
                      .communication == globalMax.communication
                      ? ' global-max'
                      : '')
                  "
                  v-text="
                    dataFormater.formatMoney(
                      researches.budgetResearch[team.univers][team.id].total
                        .communication
                    )
                  "
                ></td>
                <td
                  :class="
                    'bb-1 br-1 text-center' +
                    (researches.budgetResearch[team.univers][team.id].total
                      .marketing == universMin[team.univers].marketing
                      ? ' univers-min'
                      : '') +
                    (researches.budgetResearch[team.univers][team.id].total
                      .marketing == universMax[team.univers].marketing
                      ? ' univers-max'
                      : '') +
                    (researches.budgetResearch[team.univers][team.id].total
                      .marketing == globalMin.marketing
                      ? ' global-min'
                      : '') +
                    (researches.budgetResearch[team.univers][team.id].total
                      .marketing == globalMax.marketing
                      ? ' global-max'
                      : '')
                  "
                  v-text="
                    dataFormater.formatMoney(
                      researches.budgetResearch[team.univers][team.id].total
                        .marketing
                    )
                  "
                ></td>
                <td
                  :class="
                    'bb-1 br-1 text-center' +
                    (researches.budgetResearch[team.univers][team.id].total
                      .all == universMin[team.univers].all
                      ? ' univers-min'
                      : '') +
                    (researches.budgetResearch[team.univers][team.id].total
                      .all == universMax[team.univers].all
                      ? ' univers-max'
                      : '') +
                    (researches.budgetResearch[team.univers][team.id].total
                      .all == globalMin.all
                      ? ' global-min'
                      : '') +
                    (researches.budgetResearch[team.univers][team.id].total
                      .all == globalMax.all
                      ? ' global-max'
                      : '')
                  "
                  v-text="
                    dataFormater.formatMoney(
                      researches.budgetResearch[team.univers][team.id].total.all
                    )
                  "
                ></td>
              </tr>
            </template>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
.univers-min {
  font-weight: bolder !important;
  color: var(--v-accent-base) !important;
}
.global-min {
  border: 3px solid var(--v-accent-base) !important;
}
.univers-max {
  font-weight: bolder !important;
  color: var(--v-primary-base) !important;
}
.global-max {
  border: 3px solid var(--v-primary-base) !important;
}
</style>

<script>
import dataFormater from "@/assets/functions/dataFormater.js";
import dataExtractor from "@/assets/functions/dataExtractor.js";

import Univers from "@/classes/Univers.js";
import Team from "@/classes/Team.js";
import Period from "@/classes/Period.js";

export default {
  name: "BudgetResearch",
  props: ["session", "researches", "compactMode"],
  setup() {
    return {
      dataFormater,
      dataExtractor,
    };
  },
  data() {
    return {
      unsub: [],
      periods: [],
      teamTable: {},
      universeTable: {},
      orderedTeams: {},
    };
  },
  watch: {
    session: {
      async handler(val) {
        if (val) {
          this.loadData();
        }
      },
      immediate: true,
    },
    researches: {
      async handler(val) {
        if (val) {
          this.loadData();
        }
      },
      immediate: true,
    },
  },
  computed: {
    universMin() {
      let universMin = {};
      for (let universId in this.researches.budgetResearch) {
        if (universMin[universId] == undefined) {
          universMin[universId] = {
            communication: Infinity,
            marketing: Infinity,
            quality: Infinity,
            rnd: Infinity,
            all: Infinity,
          };
        }
        for (let teamId in this.researches.budgetResearch[universId]) {
          if (
            this.researches.budgetResearch[universId][teamId].total
              .communication < universMin[universId].communication
          ) {
            universMin[universId].communication =
              this.researches.budgetResearch[universId][
                teamId
              ].total.communication;
          }
          if (
            this.researches.budgetResearch[universId][teamId].total.marketing <
            universMin[universId].marketing
          ) {
            universMin[universId].marketing =
              this.researches.budgetResearch[universId][teamId].total.marketing;
          }
          if (
            this.researches.budgetResearch[universId][teamId].total.quality <
            universMin[universId].quality
          ) {
            universMin[universId].quality =
              this.researches.budgetResearch[universId][teamId].total.quality;
          }
          if (
            this.researches.budgetResearch[universId][teamId].total.rnd <
            universMin[universId].rnd
          ) {
            universMin[universId].rnd =
              this.researches.budgetResearch[universId][teamId].total.rnd;
          }
          if (
            this.researches.budgetResearch[universId][teamId].total.all <
            universMin[universId].all
          ) {
            universMin[universId].all =
              this.researches.budgetResearch[universId][teamId].total.all;
          }
        }
      }
      return universMin;
    },
    universMax() {
      let universMax = {};
      for (let universId in this.researches.budgetResearch) {
        if (universMax[universId] == undefined) {
          universMax[universId] = {
            communication: -1,
            marketing: -1,
            quality: -1,
            rnd: -1,
            all: -1,
          };
        }
        for (let teamId in this.researches.budgetResearch[universId]) {
          if (
            this.researches.budgetResearch[universId][teamId].total
              .communication > universMax[universId].communication
          ) {
            universMax[universId].communication =
              this.researches.budgetResearch[universId][
                teamId
              ].total.communication;
          }
          if (
            this.researches.budgetResearch[universId][teamId].total.marketing >
            universMax[universId].marketing
          ) {
            universMax[universId].marketing =
              this.researches.budgetResearch[universId][teamId].total.marketing;
          }
          if (
            this.researches.budgetResearch[universId][teamId].total.quality >
            universMax[universId].quality
          ) {
            universMax[universId].quality =
              this.researches.budgetResearch[universId][teamId].total.quality;
          }
          if (
            this.researches.budgetResearch[universId][teamId].total.rnd >
            universMax[universId].rnd
          ) {
            universMax[universId].rnd =
              this.researches.budgetResearch[universId][teamId].total.rnd;
          }
          if (
            this.researches.budgetResearch[universId][teamId].total.all >
            universMax[universId].all
          ) {
            universMax[universId].all =
              this.researches.budgetResearch[universId][teamId].total.all;
          }
        }
      }
      return universMax;
    },
    globalMin() {
      let globalMin = {
        communication: Infinity,
        marketing: Infinity,
        quality: Infinity,
        rnd: Infinity,
        all: Infinity,
      };
      for (let universId in this.researches.budgetResearch) {
        for (let teamId in this.researches.budgetResearch[universId]) {
          if (
            this.researches.budgetResearch[universId][teamId].total
              .communication < globalMin.communication
          ) {
            globalMin.communication =
              this.researches.budgetResearch[universId][
                teamId
              ].total.communication;
          }
          if (
            this.researches.budgetResearch[universId][teamId].total.marketing <
            globalMin.marketing
          ) {
            globalMin.marketing =
              this.researches.budgetResearch[universId][teamId].total.marketing;
          }
          if (
            this.researches.budgetResearch[universId][teamId].total.quality <
            globalMin.quality
          ) {
            globalMin.quality =
              this.researches.budgetResearch[universId][teamId].total.quality;
          }
          if (
            this.researches.budgetResearch[universId][teamId].total.rnd <
            globalMin.rnd
          ) {
            globalMin.rnd =
              this.researches.budgetResearch[universId][teamId].total.rnd;
          }
          if (
            this.researches.budgetResearch[universId][teamId].total.all <
            globalMin.all
          ) {
            globalMin.all =
              this.researches.budgetResearch[universId][teamId].total.all;
          }
        }
      }
      return globalMin;
    },
    globalMax() {
      let globalMax = {
        communication: -1,
        marketing: -1,
        quality: -1,
        rnd: -1,
        all: -1,
      };
      for (let universId in this.researches.budgetResearch) {
        for (let teamId in this.researches.budgetResearch[universId]) {
          if (
            this.researches.budgetResearch[universId][teamId].total
              .communication > globalMax.communication
          ) {
            globalMax.communication =
              this.researches.budgetResearch[universId][
                teamId
              ].total.communication;
          }
          if (
            this.researches.budgetResearch[universId][teamId].total.marketing >
            globalMax.marketing
          ) {
            globalMax.marketing =
              this.researches.budgetResearch[universId][teamId].total.marketing;
          }
          if (
            this.researches.budgetResearch[universId][teamId].total.quality >
            globalMax.quality
          ) {
            globalMax.quality =
              this.researches.budgetResearch[universId][teamId].total.quality;
          }
          if (
            this.researches.budgetResearch[universId][teamId].total.rnd >
            globalMax.rnd
          ) {
            globalMax.rnd =
              this.researches.budgetResearch[universId][teamId].total.rnd;
          }
          if (
            this.researches.budgetResearch[universId][teamId].total.all >
            globalMax.all
          ) {
            globalMax.all =
              this.researches.budgetResearch[universId][teamId].total.all;
          }
        }
      }
      return globalMax;
    },
  },
  methods: {
    exportTable() {
      dataExtractor.exportTable(
        "adminResearchWraper",
        "budgetResearch",
        "Etude des budgets"
      );
    },
    async loadData() {
      let listedPeriods = new Set();
      for (let universId in this.researches.budgetResearch) {
        for (let teamId in this.researches.budgetResearch[universId]) {
          for (let periodId in this.researches.budgetResearch[universId][teamId]
            .data) {
            listedPeriods.add(periodId);
          }
        }
      }

      this.unsub.push(
        Period.listenBySession(this.session.id, (periods) => {
          let tmpPeriods = [];
          for (let period of periods) {
            if (listedPeriods.has(period.id)) {
              tmpPeriods.push(period);
            }
          }

          tmpPeriods.sort((a, b) => a.end - b.end);
          this.periods = tmpPeriods;
        })
      );

      let tmp_orderedTeams = {};
      let universes = await Univers.getBySession(this.session.id);
      universes.sort((a, b) => a.name.localeCompare(b.name));

      for (let univers of universes) {
        this.universeTable[univers.id] = univers;

        let teams = await Team.getByUnivers(univers.id);
        for (let team of teams) {
          this.teamTable[team.id] = team;
        }
        tmp_orderedTeams[univers.id] = teams;
        tmp_orderedTeams[univers.id].sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      }
      this.orderedTeams = tmp_orderedTeams;
    },
  },
};
</script>
